<template>

  <v-container class="fill-height">
    <v-card>
      <h2 class="text-h4">
        Join the reunion mailing list
      </h2>
      <p class="font-weight-regular">
        This will be our primary method of contacting you with details about the event
      </p>
      <p class="font-weight-regular">
        If you've already submitted your email address in months or years past, thanks! We got you. Please share this with other SHHS alums who may not have entered their email address.
      </p>

      <v-autocomplete
        :disabled="hasSaved"
        :items="names"
        item-text="name"
        v-model="person"
        outlined
        rounded
        @input="validateInput"
        label="Search your name"
        return-object
      ></v-autocomplete>

      <v-text-field
        :disabled="hasSaved"
        label="Email"
        v-model="email"
        outlined
        rounded
        @input="validateInput"
        :rules="emailRules"
      ></v-text-field>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="3"
          color=success
          :disabled="hasSaved || !validInput"
          @click="submitEmail">
          Submit
        </v-btn>
      </v-card-actions>

      <v-snackbar
        v-model="hasSaved"
        :timeout="-1"
        absolute
        bottom
      >
        Thanks! Your email has been successfully submitted.
        <template v-slot:action="{ attrs }">
          <v-btn
            outlined
            small
            text
            v-bind="attrs"
            @click="hasSaved = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="error"
        :timeout="-1"
        absolute
        bottom
      >
        ⚠️ Your submission was not successful. Please check your input and try again. ⚠️
        <template v-slot:action="{ attrs }">
          <v-btn
            outlined
            small
            text
            v-bind="attrs"
            @click="error = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Register',
  props: {
    msg: String
  },
  data: () => ({
    names: [],
    hasSaved: false,
    error: false,
    validInput: false,
    person: null,
    email: '',
    emailRules: [
        v => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'E-mail must be valid'
      ]
  }),
    mounted: function () {
      this.getAlumni();
  },
  methods: {
    getAlumni() {
      axios({
        method: 'get',
        url: 'https://api.shhsclassof2010.com/alumnis', // this.getApiURL() + '/alumni',
        headers: {'Access-Control-Allow-Origin': '*'},
      }).then(response => this.names = response.data)
    },

    submitEmail() {
      axios({
        method: 'put',
        url: 'https://api.shhsclassof2010.com/alumnis/' + this.person._id, // this.getApiURL() + '/alumni',
        headers: {'Access-Control-Allow-Origin': '*'},
        data: {
          _id: this.person._id,
          name: this.person.name,
          email: this.email,
          emailPresent: this.person.emailPresent
        },
        validateStatus: () => true
      }).then(result => {
        if(result.status === 200){
          this.error = false
          this.hasSaved = true
        } else {
          this.error = true
        }
      });
    },

    validateInput() {
      this.validInput = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email) && this.person
    },

    getApiURL(envsubstValue='$API_URL') {
      return '$' + this.preventEnvsubstReplacement() !== envsubstValue ? envsubstValue : 'http://127.0.0.1:8080'
    },

    preventEnvsubstReplacement(obfuscation='API_URL') {
      return obfuscation
    },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  width: 55rem;
  max-width: 100%;
}

.v-card {
  width: 55rem;
  padding: 20px;
  text-align: left;
  border-radius: 1.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

</style>
